
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonContent, 
  IonButtons,
  IonButton,  
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonText,

  onIonViewDidEnter,
} from '@ionic/vue';
import { 
  bagHandle, 
  settings as settingsIcon, 
  starOutline, 
  logoFacebook,
  logoInstagram,
  logoPinterest,
  cameraOutline,
  home as homeIcon,
  } from 'ionicons/icons';
import { defineComponent, computed, ref } from '@vue/runtime-core';
import { useStore } from '@/store'
import { differenceInMonths, differenceInDays, addMonths, differenceInHours, addDays } from 'date-fns';
import { useRouter } from 'vue-router';
import { asDateObject } from '@/lib/api-endpoints';

export default  defineComponent({
  name: 'Home',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonContent, 
    IonButtons,
    IonButton,  
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
  },
  setup(){

    const store = useStore();

    const today = ref(new Date());

    const bgStyle = computed(()=>{
      if (store.state.bgImg){
        return '#fff url(\''+ store.state.bgImg.data +'\') no-repeat center center / cover'
      }
      return '#fff url(\'/assets/img/home_background.jpg\') no-repeat center center / cover'
    });

    const bgImg = computed(()=>{
      console.log(store.state.bgImg);
      if (store.state.bgImg){
        return store.state.bgImg.data;
      }
      return '/assets/img/home_background.jpg';
    });

    const timeToGo = computed(() => {
      if (!(store.state.userData && store.state.userData.wedding)){
        return {
          totalHours:-1,
          hours:0,
          days:0,
          months:0,
        };
      }
      // Apple browsers are pretty pedantic about having a 'T' between 
      // the time and date (unlike php which spits out a space).
      const weddingDate = asDateObject(store.state.userData.wedding.date);

      const weddingDateString = weddingDate.toLocaleString('default', { 
        month: 'long', // "June" 
        day: 'numeric', // "1" 
        year: 'numeric' // "2019" 
      })

      const monthsToGo = differenceInMonths(weddingDate, today.value);

      const monthsFromToday = addMonths(today.value, monthsToGo);

      const daysToGo = differenceInDays(weddingDate, monthsFromToday);

      const daysFromToday = addDays(monthsFromToday, daysToGo);

      const hoursToGo = differenceInHours(weddingDate, daysFromToday);
      
      const totalHoursToGo = differenceInHours(weddingDate, today.value);

        return {
          totalHours: totalHoursToGo,
          hours: hoursToGo,
          days: daysToGo,
          months: monthsToGo,
          weddingDateString: weddingDateString,
        };
    });

    // update today once every 5 minutes
    setInterval(()=>{ today.value = new Date() }, 300000)

    // update wedding (and user) info everytime this page is
    // opened.
    const router = useRouter();
    onIonViewDidEnter(async () => {
      const userData = await store.dispatch("getUserData", {force: false, background: true});
      if (! userData){
        // if we can't get user data, then we need to login.
        router.push("/login");
      } 
      // await not required, load image asynchronously
      store.dispatch("loadBgImg");
    });

    function uploadImage(){
      router.push("/tabs/home/upload_photo");
    }


    return {
      timeToGo,

      uploadImage,
      
      bagHandle,
      settingsIcon,
      starOutline,
      logoFacebook,
      logoInstagram,
      logoPinterest,
      cameraOutline,
      homeIcon,
      bgStyle,
      bgImg,

      store,
      router,
    }
  }
});
